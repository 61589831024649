import React from "react";

export default function Abouts() {
 return (
  <>
   <h1>About</h1>
   <p>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta fugit magnam
    molestiae nisi ex odio totam placeat. Pariatur expedita accusamus ex sunt
    numquam fugiat eum impedit, aliquid, rerum atque totam aperiam alias vitae
    dolorum.
   </p>
  </>
 );
}
